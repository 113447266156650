<template>
  <div class="mSelect">
    <!-- 不可输入 -->
    <div v-if="rotate" class="boxCont" @click="isactive = !isactive">
      <input
        :value="value"
        type="text"
        :placeholder="placeholder"
        disabled="disabled"
      />
      <span :class="isactive ? 'font isactive' : 'font'">
        <i :class="'iconfont ' + iconname" :style="fontSty"></i>
      </span>
    </div>
    <!-- 可以输入 -->
    <div v-if="!rotate" class="boxCont">
      <input ref="iptinfo" type="text" :placeholder="placeholder" />
      <span class="font" @click="selectmsg">
        <i :class="'iconfont ' + iconname" :style="fontSty"></i>
      </span>
    </div>

    <ul v-if="list.length" :class="isactive ? 'selectbox isshow' : 'selectbox'">
      <li class="triangle"></li>
      <ul>
        <template v-if="!isObj">
          <li v-for="(item, index) in list" @click.stop="clickItem(item)" :key="index">{{ item }}</li>
        </template>
        <template v-if="isObj">
          <li v-for="(item, index) in list" @click.stop="clickItem(item)" :key="index">{{ item.val }}</li>
        </template>
      </ul>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    value: String | Number,
    isObj: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: String,
    iconname: {
      type: String,
      default() {
        return "icon-below-s";
      },
    },
    rotate: {
      type: Boolean,
      default: true,
    },
    fontSty: String,
  },
  data() {
    return {
      isactive: false,
    };
  },
  methods: {
    clickItem(msg) {
      this.isactive = false;
      this.$emit("input",msg);
    },
    selectmsg() {
      let val = this.$refs.iptinfo.value.trim()
        ? this.$refs.iptinfo.value.trim()
        : Math.random() + "";
      this.$emit("input", val);
      this.isactive = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.mSelect {
  position: relative;
  box-sizing: border-box;
  padding: 0 10px;
  height: 100%;
  width: 100%;
  .boxCont {
    position: relative;
    height: 100%;
    width: 100%;
    input {
      float: left;
      box-sizing: border-box;
      width: 90%;
      height: 100%;
      border: none;
      outline: none;
      background: transparent;
    }
    .font {
      position: absolute;
      width: 10%;
      height: 100%;
      cursor: pointer;
      transition: all 0.3s;
      .iconfont {
        position: absolute;
        font-size: 10px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .isactive {
      transform: rotate(180deg);
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .selectbox {
    position: absolute;
    border-radius: 2px;
    z-index: 999;
    left: 0;
    bottom: 0;
    transform: translateY(100%) scaleY(0);
    transform-origin: 50% 0;
    width: 100%;
    transition: all 0.2s;
    .triangle {
      position: relative;
      height: 10px;
    }
    .triangle::before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 50%;
      width: 0;
      border: 6px solid #ffffff;
      border-right-color: transparent;
      border-bottom-color: transparent;
      background: transparent;
      transform: translate(-50%, 50%) rotate(45deg);
      box-shadow: 0 0 3px #8a8989;
    }
    ul {
      background: #ffffff;
      box-shadow: 0 0 3px #8a8989;
      li {
        font-size: 80%;
        box-sizing: border-box;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      li:hover {
        cursor: pointer;
        background: #f5f7fa;
      }
    }
  }
  .isshow {
    transform: translateY(100%) scaleY(1);
  }
}
</style>