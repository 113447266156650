<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div
        class="componentSty_content_title"
        style="padding: 10px; border-bottom: 1px solid #ebeef5"
      >
        <el-form inline size="small">
          <el-form-item
            label="公告列表"
            style="margin-bottom: 0"
          ></el-form-item>
          <el-form-item label="选择分类" style="float: right; margin-bottom: 0">
            <el-select v-model="condition.noticeType" @change="getNoticeList">
              <el-option
                v-for="(item, index) in enumNoticeType"
                :key="index"
                :label="item"
                :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="float: right; margin-bottom: 0">
            <el-button
              type="primary"
              size="small"
              class="buttons"
              @click="handleAddNotice"
            >
              添加公告
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table
          :data="arrayList"
          style="width: 100%; height: calc(100% - 75px)"
        >
          <el-table-column
            label="序号"
            type="index"
            width="50px"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column prop="noticeType" label="分类">
            <template v-slot="res">
              {{ enumNoticeType[res.row.noticeType] }}
            </template>
          </el-table-column>
          <el-table-column prop="noticeTitle" label="标题"> </el-table-column>
          <el-table-column label="发布时间">
            <template v-slot="res">
              {{ mydateFormat(res.row.gmtCreate, "时分秒") }}
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template v-slot="res">
              <span
                v-if="res.row.state === 1"
                class="el_spanfontstyle"
                style="color: #ff5926"
                >已禁用</span
              >
              <span
                v-if="res.row.state === 0"
                class="el_spanfontstyle"
                style="color: #13d168"
                >已启用</span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="res">
              <span
                v-if="res.row.state === 0"
                class="iconfont icon-jinyong"
                @click="changeStatus(res.row)"
              ></span>
              <span
                v-if="res.row.state === 1"
                class="iconfont icon-qiyong"
                @click="changeStatus(res.row)"
              ></span>
              <span
                v-if="res.row.id"
                class="iconfont icon-xiugai"
                @click="handleUpdateNotice(res.row)"
              ></span>
              <span
                v-if="res.row.id"
                class="iconfont icon-shanchu"
                @click="handleDelete(res.row.id)"
              ></span>
            </template>
          </el-table-column>
        </el-table>
        <div class="paginations" v-show="arrayList.length > 0">
          <el-pagination
            :page-size="condition.limit"
            layout="total,prev, pager,next, jumper"
            :total="totalCount"
            :current-page="condition.page"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mTitle from "@/components/mTitle.vue";
import mSelect from "@/components/mSelect";
export default {
  data() {
    return {
      listitem: [{ name: "公告管理" }, { name: "公告列表" }],
      enumNoticeType: {
        1: "系统公告",
        2: "车队公告",
        3: "司机公告",
      },
      totalCount: 0,
      condition: {
        page: 1,
        limit: 15,
        noticeType: "",
      },
      arrayList: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getNoticeList();
  },
  components: {
    mTitle,
    mSelect,
  },
  methods: {
    indexMethod(index) {
      return (this.condition.page - 1) * this.condition.limit + index + 1;
    },
    async getNoticeList() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/NoticeInfo/list",
        "get",
        this.condition
      );
      if (data.code == 0) {
        this.arrayList = data.data.list;
        this.totalCount = data.data.totalCount;
      }
    },
    async changeStatus(row) {
      const res = await this.$http(
        "api1",
        "/api/biz/NoticeInfo/update",
        "post",
        {
          state: row.state == 0 ? 1 : 0,
          id: row.id,
        }
      );
      if (res.data.code == 0) {
        this.$message.success("操作成功");
        this.getNoticeList();
      }
    },
    handleCurrentChange(index) {
      this.condition.page = index;
      this.getNoticeList();
    },
    handleAddNotice() {
      this.$router.push("/notice/addNotice");
    },
    handleUpdateNotice(row) {
      this.$router.push({ path: "/notice/addNotice", query: { id: row.id } });
    },
    handleDelete(id) {
      this.$messageBox
        .confirm("此操作将永久删除该内容, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/NoticeInfo/delete",
            "post",
            { ids: [id] }
          );
          if (!data || data.msg != "success")
            return this.$message.error("获取数据失败");
          this.$message.success("删除成功");
          this.getNoticeList();
        })
        .catch((err) => err);
    },
  },
};
</script>
<style lang="scss" src="@/assets/components.scss" scoped></style>